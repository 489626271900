<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-tabs>
            <b-tab @click="d_selectedTab = 'server_nodes'">
              <template slot="title">
                <img src="@/icon/665778.png" style="width: 1.5em;">
                <span :style="'color: green'">Server</span>
              </template>
              <template v-if="d_selectedTab === 'server_nodes'">
                <b-card :class="[d_fullScreenNodeList ? 'full-screen-mode' : 'normal-mode']">
                  <b-card-header>
                    Server <small style="color: darkgreen;">[active: {{ d_totalActiveProcessCount }}]</small>
                    <b-button class="pull-right" size="md" :variant="d_fullScreenNodeList ? 'warning' : 'secondary'" :style="d_fullScreenNodeList ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenNodeList ? d_fullScreenNodeList = false : d_fullScreenNodeList = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-row style="cursor: pointer; padding: 5px;">
                      <!-- <b-col sm="12" md="11" @click="f_selectThisProject(node_ind)" @dblclick="go_to_this_project_details(project.id)"> -->
                      <b-col sm="12" md="3">
                        Name
                      </b-col>
                      <b-col sm="12" md="1">
                        Status
                      </b-col>
                      <b-col sm="12" md="1">
                        Cpu
                      </b-col>
                      <b-col sm="12" md="1">
                        Ram (total)
                      </b-col>
                      <b-col sm="12" md="1">
                        Cpu (percent)
                      </b-col>
                      <b-col sm="12" md="1">
                        Ram (percent)
                      </b-col>
                      <b-col sm="12" md="1">
                        Ram (free)
                      </b-col>
                      <b-col sm="12" md="1">
                        Ram (available)
                      </b-col>
                      <b-col sm="12" md="1">Process</b-col>
                      <b-col sm="12" md="1"></b-col>
                    </b-row>
                  </b-card-header>
                  <template v-if="p_processInfo.last">
                    <b-card v-for="(node, node_ind) in p_processInfo.last" no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-row v-b-toggle="'accordion_' + node_ind.toString()" :style="d_selectedNodeIndex === node_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px;' : 'cursor: pointer; padding: 5px;'">
                          <b-col sm="12" md="3">
                            <div>
                              <strong>{{ node_ind + 1 }} - {{ node.node.name.val }} </strong> {{ node.node.id.val }} [ {{ node.node.ip }} ]
                            </div>
                            <div>
                              {{ DateFormatWithHourMin(node.app_process_list.date) }} / {{ node.app_process_list.get_app_process_list_prepare_count }}
                            </div>
                          </b-col>
                          <b-col sm="12" md="1">
                            {{ node.app_process_list.status }}
                          </b-col>
                          <b-col sm="12" md="1">
                            {{ node.app_process_list.cpu_count }}
                          </b-col>
                          <b-col sm="12" md="1">
                            <!-- {{ (node.app_process_list.memory_total_physical / (1024 * 1024 * 1024)).toFixed(0) }} -->
                            {{ (node.app_process_list.memory_total_physical / 1000000000).toFixed(1) }}
                          </b-col>
                          <b-col sm="12" md="1">
                            {{ node.app_process_list.cpu_percent }}
                          </b-col>
                          <b-col sm="12" md="1">
                            {{ node.app_process_list.memory.percent }}
                          </b-col>
                          <b-col sm="12" md="1">
                            <!-- {{ (node.app_process_list.memory.free / (1024 * 1024 * 1024)).toFixed(0) }} -->
                            {{ (node.app_process_list.memory.free / 1000000000).toFixed(1) }}
                          </b-col>
                          <b-col sm="12" md="1">
                            <!-- {{ (node.app_process_list.memory.free / (1024 * 1024 * 1024)).toFixed(0) }} -->
                            {{ (node.app_process_list.memory.available / 1000000000).toFixed(1) }}
                          </b-col>
                          <b-col sm="12" md="1">
                            {{ node.active_process_list.length }}
                          </b-col>
                          <b-col sm="12" md="1">
                            <b-btn block @click="f_goToWisdomStats()" variant="info">*</b-btn>
                            <!-- <b-dropdown variant="link" size="lg" no-caret>
                      <template style="" slot="button-content">o: Sized
                        &#x1f50d;
                      </template>
                      <b-dropdown-item @click="f_goToWisdomStats(project.id)"> Walgorithm Projesine Git </b-dropdown-item>
                      <b-dropdown-item @click="f_deletePolestarProject(project.id)"> Projeyi Sil </b-dropdown-item>
                    </b-dropdown> -->
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <b-collapse :id="'accordion_' + node_ind.toString()" :visible="false" accordion="my-accordion" role="tabpanel">
                        <b-card-body style="background: #e8fffc;">
                          <b-row style="padding: 5px;">
                            <b-col sm="12" md="4">
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Server User:</strong> {{ node.node.username.val }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Total Process Type: </strong> {{ Object.keys(node.app_process_list.list).length }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Active Process: </strong> {{ node.active_process_list.length }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Ram (available) </strong> {{ (node.app_process_list.memory.available / 1000000000).toFixed(1) }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Ram (used) </strong> {{ (node.app_process_list.memory.used / 1000000000).toFixed(1) }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Ram (inactive) </strong> {{ (node.app_process_list.memory.inactive / 1000000000).toFixed(1) }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Ram (shared) </strong> {{ (node.app_process_list.memory.shared / 1000000000).toFixed(1) }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Ram (slab) </strong> {{ (node.app_process_list.memory.slab / 1000000000).toFixed(1) }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Ram (buffers) </strong> {{ (node.app_process_list.memory.buffers / 1000000000).toFixed(1) }}
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Aktif İşlemler</strong>
                                </b-col>
                              </b-row>
                              <b-row v-for="(active_pro, active_pro_index) in node.active_process_list" style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong> {{ active_pro.label }} : </strong> {{ active_pro.count }} / {{ active_pro.max_process }} ({{ active_pro.total_count }}) [{{ active_pro.next_cluster_index }}]
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12" md="4">
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </template>
                </b-card>
              </template>
            </b-tab>
            <b-tab @click="d_selectedTab = 'process_list'">
              <template slot="title">
                <img src="@/icon/665778.png" style="width: 1.5em;">
                <span :style="'color: green'">Server Process List</span>
              </template>
              <template v-if="d_selectedTab === 'process_list'">
                <b-card :class="[d_fullScreenTable ? 'full-screen-mode' : 'normal-mode']" style="margin-bottom: 50px;">
                  <b-card-header>
                    Server Process List <small style="color: darkgreen;">[active: {{ d_totalActiveProcessCount }}]</small>
                    <b-button class="pull-right" size="md" :variant="d_fullScreenTable ? 'warning' : 'secondary'" :style="d_fullScreenTable ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenTable ? d_fullScreenTable = false : d_fullScreenTable = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-row>
                    <b-col cols="10">
                      <b-form-input v-model="d_searchProcess" placeholder="write for filtering"></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-dropdown variant="primary" size="md" class="pull-right">
                        <template slot="button-content">process operations</template>
                        <b-dropdown-item @click="f_processInfoLogActiveProcessIdDetails('this')">show active not ended processes</b-dropdown-item>
                        <b-dropdown-item @click="f_processInfoDeleteLog('all')">clear process logs</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'continue'" @click="f_timerProcessTypeChange()">freeze process view</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'wait'" @click="f_timerProcessTypeChange()">continue process view</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <b-row v-if="p_processInfo.last">
                    <b-col cols="12">
                      <div id="processtable" class="general_scroll_class" :style="f_calculateTableStyle()">
                        <table border="1" style="table-layout: fixed; overflow-wrap: break-word;">
                          <thead>
                            <tr>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;"></th>
                              <template v-for="(node, node_ind) in p_processInfo.last">
                                <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">
                                  {{ node.node.name.val }}
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(pro, process_key) in p_processInfo.last[0].app_process_list.list">
                              <tr v-if="f_searchProcessFilter(pro, process_key)">
                                <td style="padding: 5px;">
                                  <b-dropdown variant="white" size="md">
                                    <template slot="button-content"> {{ pro.name ? pro.name : process_key }} </template>
                                    <b-dropdown-item @click="f_processInfoLogActiveProcessIdDetails('this', process_key)">show active not ended processes</b-dropdown-item>
                                    <b-dropdown-item @click="f_processInfoDeleteLog('this', process_key)">clear process logs</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                                <template v-for="(node, node_ind) in p_processInfo.last">
                                  <td :style="f_calculateProcessTdStyle(node.app_process_list.list[process_key])">
                                    {{ node.app_process_list.list[process_key].count }} / {{ node.app_process_list.list[process_key].max_process }} ({{ node.app_process_list.list[process_key].total_count }}) 
                                    <!-- {{ node.app_process_list.list[process_key].total_count_general ? node.app_process_list.list[process_key].total_count_general : '' }} -->
                                    <!-- [{{ node.app_process_list.list[process_key].next_cluster_index }}] -->
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-tab>
            <b-tab @click="d_selectedTab = 'process_list_active'">
              <template slot="title">
                <img src="@/icon/665778.png" style="width: 1.5em;">
                <span :style="'color: green'">Active</span>
              </template>
              <template v-if="d_selectedTab === 'process_list_active'">
                <b-card :class="[d_fullScreenTable ? 'full-screen-mode' : 'normal-mode']" style="margin-bottom: 50px;">
                  <b-card-header>
                    Active <small style="color: darkgreen;">[active: {{ d_totalActiveProcessCount }}]</small>
                    <b-button class="pull-right" size="md" :variant="d_fullScreenTable ? 'warning' : 'secondary'" :style="d_fullScreenTable ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenTable ? d_fullScreenTable = false : d_fullScreenTable = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-row>
                    <b-col cols="10">
                      <b-form-input v-model="d_searchProcess" placeholder="write for filtering"></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-dropdown variant="primary" size="md" class="pull-right">
                        <template slot="button-content">process operations</template>
                        <b-dropdown-item @click="f_processInfoLogActiveProcessIdDetails('this')">show active not ended processes</b-dropdown-item>
                        <b-dropdown-item @click="f_processInfoDeleteLog('all')">clear process logs</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'continue'" @click="f_timerProcessTypeChange()">freeze process view</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'wait'" @click="f_timerProcessTypeChange()">continue process view</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <b-row v-if="p_processInfo.last">
                    <b-col cols="12">
                      <div id="processtable" class="general_scroll_class" :style="f_calculateTableStyle()">
                        <table border="1" style="table-layout: fixed; overflow-wrap: break-word;">
                          <thead>
                            <tr>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;"></th>
                              <template v-for="(node, node_ind) in p_processInfo.last">
                                <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">
                                  {{ node.node.name.val }}
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(pro, process_key) in p_processInfo.last[0].app_process_list.list">
                              <tr v-if="f_searchProcessFilter(pro, process_key)">
                                <td style="padding: 5px;">
                                  <b-dropdown variant="white" size="md">
                                    <template slot="button-content"> {{ pro.name ? pro.name : process_key }} </template>
                                    <b-dropdown-item @click="f_processInfoLogActiveProcessIdDetails('this', process_key)">show active not ended processes</b-dropdown-item>
                                    <b-dropdown-item @click="f_processInfoDeleteLog('this', process_key)">clear process logs</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                                <template v-for="(node, node_ind) in p_processInfo.last">
                                  <td :style="f_calculateProcessTdStyle(node.app_process_list.list[process_key])">
                                    {{ node.app_process_list.list[process_key].count }} / {{ node.app_process_list.list[process_key].max_process }} ({{ node.app_process_list.list[process_key].total_count }}) [{{ node.app_process_list.list[process_key].next_cluster_index }}]
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-tab>
            <b-tab @click="d_selectedTab = 'row_process_list_general_info'">
              <template slot="title">
                <img src="@/icon/665778.png" style="width: 1.5em;">
                <span :style="'color: green'">Row Based General Info</span>
              </template>
              <template v-if="d_selectedTab === 'row_process_list_general_info'">
                <b-card :class="[d_fullScreenTable ? 'full-screen-mode' : 'normal-mode']" style="margin-bottom: 50px;">
                  <b-card-header>
                    Row Process General Info <small style="color: darkgreen;">[rows: {{ d_rowProcessListGeneralReport.total_process_count }}]</small>
                    <b-button class="pull-right" size="md" :variant="d_fullScreenTable ? 'warning' : 'secondary'" :style="d_fullScreenTable ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenTable ? d_fullScreenTable = false : d_fullScreenTable = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-row>
                    <b-col cols="10">
                    </b-col>
                    <b-col cols="2">
                      <b-dropdown variant="primary" size="md" class="pull-right">
                        <template slot="button-content">process operations</template>
                        <b-dropdown-item @click="f_processInfoLogActiveProcessIdDetails('this')">show active not ended processes</b-dropdown-item>
                        <b-dropdown-item @click="f_processInfoDeleteLog('all')">clear process logs</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'continue'" @click="f_timerProcessTypeChange()">freeze process view</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'wait'" @click="f_timerProcessTypeChange()">continue process view</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div id="processtable" class="general_scroll_class" :style="f_calculateTableStyle()">
                        <b-row>
                          <b-col cols="3">
                            <table border="1" style="table-layout: fixed; overflow-wrap: break-word; width: 100%;">
                              <thead>
                                <tr>
                                  <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;" colspan="2">Date List</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(date, date_ind) in d_rowProcessListGeneralReport.date">
                                  <tr>
                                    <td colspan="2">{{ date }}</td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </b-col>
                          <b-col cols="3">
                            <table border="1" style="table-layout: fixed; overflow-wrap: break-word; width: 100%;">
                              <thead>
                                <tr>
                                  <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;" colspan="2">General Information</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><strong>Total Process Count</strong></td>
                                  <td> {{ d_rowProcessListGeneralReport.total_process_count}} </td>
                                </tr>
                                <tr>
                                  <td><strong>Total Data Size (mb)</strong></td>
                                  <td> {{ d_rowProcessListGeneralReport.data_size_mb.toFixed(2)}} </td>
                                </tr>
                              </tbody>
                            </table>
                          </b-col>
                          <b-col cols="3">
                            <table border="1" style="table-layout: fixed; overflow-wrap: break-word; width: 100%;">
                              <thead>
                                <tr>
                                  <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;" colspan="2">User List</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(user, user_id) in d_rowProcessListGeneralReport.user">
                                  <tr>
                                    <td>{{ user_id }}</td>
                                    <td> {{ user.count}} </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </b-col>
                          <b-col cols="3">
                            <table border="1" style="table-layout: fixed; overflow-wrap: break-word; width: 100%;">
                              <thead>
                                <tr>
                                  <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;" colspan="2">System Process Id List</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(system_process, system_process_id) in d_rowProcessListGeneralReport.system_process_id">
                                  <tr>
                                    <td>{{ system_process_id }}</td>
                                    <td> {{ system_process.count}} </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-tab>
            <b-tab @click="d_selectedTab = 'row_process_list'">
              <template slot="title">
                <img src="@/icon/665778.png" style="width: 1.5em;">
                <span :style="'color: green'">Row Based List</span>
              </template>
              <template v-if="d_selectedTab === 'row_process_list'">
                <b-card :class="[d_fullScreenTable ? 'full-screen-mode' : 'normal-mode']" style="margin-bottom: 50px;">
                  <b-card-header>
                    Row Based Process List <small style="color: darkgreen;">[rows: {{ d_rowProcessListGeneralReport.total_process_count }}]</small>
                    <b-button class="pull-right" size="md" :variant="d_fullScreenTable ? 'warning' : 'secondary'" :style="d_fullScreenTable ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenTable ? d_fullScreenTable = false : d_fullScreenTable = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-row>
                    <b-col cols="8">
                      <b-form-input v-model="d_searchRow" placeholder="write for filtering"></b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <b-dropdown variant="primary" size="md" class="pull-right">
                        <template slot="button-content">all log process</template>
                        <b-dropdown-item @click="f_processInfoLogActiveProcessIdDetails('this')">show active not ended processes</b-dropdown-item>
                        <b-dropdown-item @click="f_processInfoDeleteLog('all')">clear process logs</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'continue'" @click="f_timerProcessTypeChange()">freeze process view</b-dropdown-item>
                        <b-dropdown-item v-if="d_timerProcessType === 'wait'" @click="f_timerProcessTypeChange()">continue process view</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div id="processtable" class="general_scroll_class" :style="f_calculateTableStyle()">
                        <table border="1" style="table-layout: fixed; overflow-wrap: break-word;">
                          <thead>
                            <tr>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Server</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">User</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Date</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Process Name</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Process Type</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">System Process Id</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Process Id</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Data Size (mb)</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Error Type</th>
                              <th style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; background-color: greenyellow;">Note</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(node, node_ind) in p_processInfo.last">
                              <template v-for="(row, row_ind) in node.app_process_list.row_process_list.rows">
                                <tr v-if="f_searchRowsFilter(node.node.name.val, row)">
                                  <td>{{ node.node.name.val }}</td>
                                  <td>{{ row.user ? row.user : '' }}</td>
                                  <td>{{ row.date ? row.date : '' }}</td>
                                  <td>{{ row.process_name ? row.process_name : '' }}</td>
                                  <td>{{ row.process_type ? row.process_type : '' }}</td>
                                  <td>{{ row.system_process_id ? row.system_process_id : '' }}</td>
                                  <td>{{ row.process_id ? row.process_id : '' }}</td>
                                  <td>{{ row.data_size_mb ? row.data_size_mb : '' }}</td>
                                  <td>{{ row.error_type ? row.error_type : '' }}</td>
                                  <td>{{ row.note ? row.note : '' }}</td>
                                </tr>
                              </template>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="d_showTextFilter" v-model="d_showTextFilter" size="md" title="Arama İfadesi" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="4">
          Arama İfadesi
        </b-col>
        <b-col cols="8">
          <b-input type="text" id="d_searchText" placeholder="Arama İfadesi Giriniz" v-model="d_searchText"></b-input>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_processInfoLogActiveProcessIdDetails.show" v-model="d_processInfoLogActiveProcessIdDetails.show" size="md" title="Arama İfadesi" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="12">
          <div style="white-space: pre;">
            {{ f_getJsonStringify(d_processInfoLogActiveProcessIdDetails.list) }}
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import WalgorithmService from '@/services/walgorithm';
import WanalyzerService from '@/services/wanalyzer';
import WruleengineService from '@/services/wruleengine';
import WmanagerService from '@/services/wmanager';
import {
  default as router
} from '@/router';
import { mapGetters } from 'vuex';
import store from '@/store';
import moment from 'moment';

export default {
  name: 'ApplicationProcessInfoGeneral',
  components: {},
  props: {
    p_processInfo: {
      type: Object,
      required: true
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  data () {
    return {
      d_timerProcessType: 'continue',
      d_rowProcessListGeneralReport: {},
      d_searchRow: '',
      d_totalActiveProcessCount: 0,
      d_processInfoLogActiveProcessIdDetails: { 'list': [], 'show': false },
      WalgorithmService: WalgorithmService,
      WanalyzerService: WanalyzerService,
      WruleengineService: WruleengineService,
      WmanagerService: WmanagerService,
      d_fullScreenNodeList: false,
      d_searchProcess: '',
      d_fullScreenTable: false,
      d_selectedNodeIndex: '',
      d_selectedTab: 'server_nodes',
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_getProcessInfo();
    this.f_getProcessInfoTimer();
  },
  destroyed () {
    window.clearInterval(this.p_processInfo.timer);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_timerProcessTypeChange: function () {
      if (this.d_timerProcessType === 'continue') {
        this.d_timerProcessType = 'wait';
      } else {
        this.d_timerProcessType = 'continue';
      }
      this.$forceUpdate();
    },
    f_searchRowsFilter: function (node_name, row) {
      let res = false;
      if (this.d_searchRow) {
        let is_node_name = false;
        let is_user = false;
        if (row.user) {
          is_user = row.user.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_date = false;
        if (row.date) {
          is_date = row.date.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_process_name = false;
        if (row.process_name) {
          is_process_name = row.process_name.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_process_type = false;
        if (row.process_type) {
          is_process_type = row.process_type.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_system_process_id = false;
        if (row.system_process_id) {
          is_system_process_id = row.system_process_id.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_process_id = false;
        if (row.process_id) {
          is_process_id = row.process_id.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_data_size_mb = false;
        if (row.data_size_mb) {
          is_data_size_mb = row.data_size_mb.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_error_type = false;
        if (row.error_type) {
          is_error_type = row.error_type.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        let is_note = false;
        if (row.note) {
          is_note = row.note.toLocaleLowerCase().indexOf(this.d_searchRow.toLocaleLowerCase()) !== -1;
        }
        if (is_user || is_date || is_process_name || is_process_type || is_system_process_id || is_process_id || is_data_size_mb || is_error_type || is_note) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_searchProcessFilter: function (pro, process_key) {
      let res = true;
      if (this.d_selectedTab === 'process_list') {
        if (pro.name) {
          res = !this.d_searchProcess || (this.d_searchProcess && pro.name.toLocaleLowerCase().indexOf(this.d_searchProcess.toLocaleLowerCase()) !== -1)
        } else {
          res = !this.d_searchProcess || (this.d_searchProcess && process_key.toLocaleLowerCase().indexOf(this.d_searchProcess.toLocaleLowerCase()) !== -1)
        }
      } else if (this.d_selectedTab === 'process_list_active') {
        let is_there_active_process = false;
        for (let node_index in this.p_processInfo.last) {
          let node = this.p_processInfo.last[node_index];
          if (node.app_process_list.list[process_key].count > 0) {
            is_there_active_process = true;
            break;
          }
        }
        // console.log(is_there_active_process);
        if (is_there_active_process) {
          if (pro.name) {
            res = !this.d_searchProcess || (this.d_searchProcess && pro.name.toLocaleLowerCase().indexOf(this.d_searchProcess.toLocaleLowerCase()) !== -1)
          } else {
            res = !this.d_searchProcess || (this.d_searchProcess && process_key.toLocaleLowerCase().indexOf(this.d_searchProcess.toLocaleLowerCase()) !== -1)
          }
        } else {
          res = false;
        }
      }
      return res;
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    DateFormatWithHourMin: function (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
      } else {
        return 'not date';
      }
    },
    f_calculateTableStyle: function () {
      let style = 'overflow-x: hidden; overflow-y: auto;';
      if (this.d_fullScreenTable) {
        style += 'height: ' + (window.innerHeight - 100).toString() + 'px;';
      } else {
        style += 'height: ' + (window.innerHeight - 375).toString() + 'px;';
      }
      return style;
    },
    f_calculateProcessTdStyle: function (pro) {
      let style = '';
      if (pro.count > 0) {
        if (pro.count < pro.max_process) {
          style = 'background-color: #c8fbc8;';
        } else if (pro.count >= pro.max_process) {
          style = 'background-color: #ffbfbf;';
        }
      }
      return style;
    },
    f_getProcessInfoTimer: function () {
      this.p_processInfo.timer = setInterval(function () {
        if (this.p_processInfo.list.length > 0) {
          if (this.d_timerProcessType === 'continue') {
            this.f_getProcessInfo();
          }
        }
      }.bind(this), 10000);
    },
    f_processInfoLogActiveProcessIdDetails: function (log_type = 'all', process_name = '') {
      let query = '';
      if (log_type) {
        query += 'log_type=' + log_type;
      }
      if (process_name) {
        query += '&process_name=' + process_name;
      }
      let ApplicationService = '';
      if (this.p_processInfo.cluster_name === 'wanalyzer') {
        ApplicationService = WanalyzerService;
      } else if (this.p_processInfo.cluster_name === 'walgorithm') {
        ApplicationService = WalgorithmService;
      } else if (this.p_processInfo.cluster_name === 'wruleengine') {
        ApplicationService = WruleengineService;
      } else if (this.p_processInfo.cluster_name === 'wmanager') {
        ApplicationService = WmanagerService;
      }
      ApplicationService.process_info_log_active_process_id_details(query)
        .then(resp => {
          if (resp.data.status_code.substring(1, 4) === '000') {
            this.d_processInfoLogActiveProcessIdDetails.list = resp.data.list;
            this.d_processInfoLogActiveProcessIdDetails.show = true;
            this.$forceUpdate();
          } else {
            alert(resp.data.status_code + ' / ' + resp.data.status_message);
          }
        });

    },
    f_processInfoDeleteLog: function (log_type = 'all', process_name = '') {
      let query = '';
      if (log_type) {
        query += 'log_type=' + log_type;
      }
      if (process_name) {
        query += '&process_name=' + process_name;
      }
      let ApplicationService = '';
      if (this.p_processInfo.cluster_name === 'wanalyzer') {
        ApplicationService = WanalyzerService;
      } else if (this.p_processInfo.cluster_name === 'walgorithm') {
        ApplicationService = WalgorithmService;
      } else if (this.p_processInfo.cluster_name === 'wruleengine') {
        ApplicationService = WruleengineService;
      } else if (this.p_processInfo.cluster_name === 'wmanager') {
        ApplicationService = WmanagerService;
      }
      ApplicationService.process_info_delete_log(query)
        .then(resp => {
          if (resp.data.status_code.substring(1, 4) === '000') {
            alert('Başarıyla temizlendi.');
            this.$forceUpdate();
          } else {
            alert(resp.data.status_code + ' / ' + resp.data.status_message);
          }
        });

    },
    f_getProcessInfo: function () {
      this.d_totalActiveProcessCount = 0;
      let info_type = 'all';
      let query = 'info_type=' + info_type;
      let ApplicationService = '';
      if (this.p_processInfo.cluster_name === 'wanalyzer') {
        ApplicationService = WanalyzerService;
      } else if (this.p_processInfo.cluster_name === 'walgorithm') {
        ApplicationService = WalgorithmService;
      } else if (this.p_processInfo.cluster_name === 'wruleengine') {
        ApplicationService = WruleengineService;
      } else if (this.p_processInfo.cluster_name === 'wmanager') {
        ApplicationService = WmanagerService;
      }
      ApplicationService.process_info(query)
        .then(resp => {
          if (resp.data.status_code.substring(1, 4) === '000') {
            let object_list = [];
            this.d_rowProcessListGeneralReport.date = [];
            this.d_rowProcessListGeneralReport.user = {};
            this.d_rowProcessListGeneralReport.system_process_id = {};
            this.d_rowProcessListGeneralReport.total_process_count = 0;
            this.d_rowProcessListGeneralReport.data_size_mb = 0;
            for (let i in resp.data.process_info) {
              if (resp.data.process_info[i].app_process_list && resp.data.process_info[i].app_process_list.row_process_list) {
                if (resp.data.process_info[i].app_process_list.row_process_list.total_process_count) {
                  this.d_rowProcessListGeneralReport.total_process_count += resp.data.process_info[i].app_process_list.row_process_list.total_process_count;
                }
                if (resp.data.process_info[i].app_process_list.row_process_list.data_size_mb) {
                  this.d_rowProcessListGeneralReport.data_size_mb += resp.data.process_info[i].app_process_list.row_process_list.data_size_mb;
                }
                if (resp.data.process_info[i].app_process_list.row_process_list.date) {
                  for (let date_index in resp.data.process_info[i].app_process_list.row_process_list.date) {
                    let dt = resp.data.process_info[i].app_process_list.row_process_list.date[date_index];
                    if (this.d_rowProcessListGeneralReport.date.indexOf(dt) === -1) {
                      this.d_rowProcessListGeneralReport.date.push(dt);
                    }
                  }
                }
                if (resp.data.process_info[i].app_process_list.row_process_list.user) {
                  for (let u in resp.data.process_info[i].app_process_list.row_process_list.user) {
                    if (!this.d_rowProcessListGeneralReport.user[u]) {
                      this.d_rowProcessListGeneralReport.user[u] = { 'count': 0 };
                    }
                    this.d_rowProcessListGeneralReport.user[u]['count'] += resp.data.process_info[i].app_process_list.row_process_list.user[u].count;
                  }
                }
                if (resp.data.process_info[i].app_process_list.row_process_list.system_process_id) {
                  for (let s in resp.data.process_info[i].app_process_list.row_process_list.system_process_id) {
                    let spi_label = resp.data.process_info[i].node.name.val + '-' + s;
                    if (!this.d_rowProcessListGeneralReport.system_process_id[spi_label]) {
                      this.d_rowProcessListGeneralReport.system_process_id[spi_label] = { 'count': 0 };
                    }
                    this.d_rowProcessListGeneralReport.system_process_id[spi_label]['count'] += resp.data.process_info[i].app_process_list.row_process_list.system_process_id[s].count;
                  }
                }
              }
              let new_obj = { 'label': resp.data.process_info[i].node.name.val };
              for (let k in resp.data.process_info[i]) {
                new_obj[k] = resp.data.process_info[i][k];
              }
              new_obj.active_process_list = [];
              for (let process_key in resp.data.process_info[i].app_process_list.list) {
                let new_process = resp.data.process_info[i].app_process_list.list[process_key];
                if (new_process.count > 0) {
                  this.d_totalActiveProcessCount += new_process.count;
                  new_process['label'] = process_key;
                  new_obj.active_process_list.push(new_process);
                }
                if (new_process.total_count_general) {
                  new_obj.total_count_general = new_process.total_count_general;
                }
              }
              object_list.push(new_obj);
            }
            let param = 'label';
            let asc_or_desc = 1;
            // asc_or_desc = -1;

            function compare(a, b) {
              // Use toUpperCase() to ignore character casing
              let param_a = '';
              let param_b = '';
              try {
                param_a = a[param]
                param_b = b[param]
              } catch (err) {
                param_a = a[param];
                param_b = b[param];
              }

              let comparison = 0;
              if (param_a > param_b) {
                comparison = 1;
              } else if (param_a < param_b) {
                comparison = -1;
              }
              return comparison * asc_or_desc;
            }
            object_list.sort(compare);
            this.p_processInfo.last = object_list;
            if (this.p_processInfo.list.length < 30) {
              this.p_processInfo.list.push(object_list);
            } else {
              this.p_processInfo.list.splice(0, 1);
              this.p_processInfo.list.push(object_list);
            }
            this.$forceUpdate();
          } else {
            alert(resp.data.status_code + ' / ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
}

</script>

<style type="text/css">
table,
tr,
th,
td {
  border: solid 0.5px lightseagreen;
  font-size: 10px;
  text-align: center;
}

.general_scroll_class::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

.general_scroll_class::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.general_scroll_class::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

